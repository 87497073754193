import {
  Array,
  Boolean,
  Dictionary,
  Lazy,
  Literal,
  Number,
  Optional,
  Record as RRecord,
  Runtype,
  Static,
  String,
  Union,
  Unknown,
} from "runtypes";

import { DisplayTableColumnOutputType } from "../display-table/outputTypes.js";
import { assertNever } from "../errors.js";
import { getNormalEnum } from "../runtypeEnums.js";
import { typedObjectValues } from "../utils/typedObjects.js";

// These types are based off of the types in /data-service/src/main/java/com/hex/sql_gen
// and should be kept in sync.

export const CalciteTypeLiteral = Union(
  Literal("VARCHAR"),
  Literal("INTEGER"),
  Literal("BIGINT"),
  Literal("FLOAT"),
  Literal("DOUBLE"),
  Literal("DECIMAL"),
  Literal("BOOLEAN"),
  Literal("DATE"),
  Literal("TIMESTAMP"),
  Literal("TIMESTAMPTZ"),
);

export const CalciteType = getNormalEnum(CalciteTypeLiteral);
export type CalciteType = Static<typeof CalciteTypeLiteral>;

export const NUMERIC_CALCITE_TYPES: CalciteType[] = [
  CalciteType.BIGINT,
  CalciteType.FLOAT,
  CalciteType.DOUBLE,
  CalciteType.INTEGER,
  CalciteType.DECIMAL,
];

export const TEMPORAL_CALCITE_TYPES: CalciteType[] = [
  CalciteType.DATE,
  CalciteType.TIMESTAMP,
  CalciteType.TIMESTAMPTZ,
];

export function columnTypeToCalciteType(
  columnType: DisplayTableColumnOutputType,
): CalciteType {
  switch (columnType) {
    case "STRING":
      return "VARCHAR";
    case "NUMBER":
      return "FLOAT";
    case "DATETIME":
      return "TIMESTAMP";
    case "DATETIMETZ":
      return "TIMESTAMPTZ";
    case "TIME":
      // this isn't really correct
      return "TIMESTAMP";
    case "UNKNOWN":
      // this probably isn't correct either
      return "VARCHAR";
    case "BOOLEAN":
    case "DATE":
      return columnType;
    default:
      assertNever(columnType, columnType);
  }
}

export function calciteTypeToColumnType(
  calciteType: CalciteType,
): DisplayTableColumnOutputType {
  switch (calciteType) {
    case "VARCHAR":
      return "STRING";
    case "INTEGER":
    case "DOUBLE":
    case "BIGINT":
    case "FLOAT":
    case "DECIMAL":
      return "NUMBER";
    case "TIMESTAMP":
      return "DATETIME";
    case "TIMESTAMPTZ":
      return "DATETIMETZ";
    case "BOOLEAN":
    case "DATE":
      return calciteType;
    default:
      assertNever(calciteType, calciteType);
  }
}

export const HqlSortOrderLiteral = Union(
  Literal("ASCENDING"),
  Literal("DESCENDING"),
);
export const HqlSortOrder = getNormalEnum(HqlSortOrderLiteral);
export type HqlSortOrder = Static<typeof HqlSortOrderLiteral>;

export const HqlUnaryOperatorLiteral = Union(
  Literal("MINUS"),
  Literal("LOGICALNOT"),
);
export const HqlUnaryOperator = getNormalEnum(HqlUnaryOperatorLiteral);
export type HqlUnaryOperator = Static<typeof HqlUnaryOperatorLiteral>;

export const HqlBinaryOperatorLiteral = Union(
  Literal("PLUS"),
  Literal("MINUS"),
  Literal("MULTIPLY"),
  Literal("DIVIDE"),
  Literal("EXPONENT"),
  Literal("MODULO"),
  Literal("EQUAL"),
  Literal("NOTEQUAL"),
  Literal("GREATER"),
  Literal("GREATEREQUAL"),
  Literal("LESS"),
  Literal("LESSEQUAL"),
  Literal("LOGICALOR"),
  Literal("LOGICALAND"),
  Literal("CONCAT"),
);
export const HqlBinaryOperator = getNormalEnum(HqlBinaryOperatorLiteral);
export type HqlBinaryOperator = Static<typeof HqlBinaryOperatorLiteral>;

export const HqlTruncUnitLiteral = Union(
  Literal("year"),
  Literal("quarter"),
  Literal("month"),
  Literal("week"),
  Literal("day"),
  Literal("hour"),
  Literal("minute"),
  Literal("second"),
  Literal("dayofweek"),
);
export const HqlTruncUnit = getNormalEnum(HqlTruncUnitLiteral);
export type HqlTruncUnit = Static<typeof HqlTruncUnitLiteral>;

const UnaryPredicateFunctions = [
  Literal("IsNull"),
  Literal("IsFinite"),
] as const;

const BinaryArgPredicateFunctions = [
  Literal("Contains"),
  Literal("StartsWith"),
  Literal("EndsWith"),
] as const;

export const HqlUnaryPredicateFunctionLiteral = Union(
  ...UnaryPredicateFunctions,
);
export const HqlUnaryPredicateFunction = getNormalEnum(
  HqlUnaryPredicateFunctionLiteral,
);
export type HqlUnaryPredicateFunction = Static<
  typeof HqlUnaryPredicateFunctionLiteral
>;

export const HqlBinaryPredicateFunctionLiteral = Union(
  ...BinaryArgPredicateFunctions,
);
export const HqlBinaryPredicateFunction = getNormalEnum(
  HqlBinaryPredicateFunctionLiteral,
);
export type HqlBinaryPredicateFunction = Static<
  typeof HqlBinaryPredicateFunctionLiteral
>;

export const HqlScalarFunctionLiteral = Union(
  ...UnaryPredicateFunctions,
  ...BinaryArgPredicateFunctions,
  Literal("Abs"),
  Literal("Round"),
  Literal("Ceiling"),
  Literal("Floor"),
  Literal("Power"),
  Literal("Sqrt"),
  Literal("Exp"),
  Literal("Lower"),
  Literal("Upper"),
  Literal("Concat"),
  Literal("Length"),
  Literal("Left"),
  Literal("Right"),
  Literal("Substitute"),
  Literal("Coalesce"),
  Literal("ToText"),
  Literal("ToNumber"),
  Literal("ToDatetime"),
  Literal("ToBoolean"),
  Literal("Year"),
  Literal("Quarter"),
  Literal("Month"),
  Literal("Day"),
  Literal("DayOfWeek"),
  Literal("Hour"),
  Literal("Minute"),
  Literal("Second"),
  Literal("Millisecond"),
  Literal("TruncYear"),
  Literal("TruncQuarter"),
  Literal("TruncMonth"),
  Literal("TruncWeek"),
  Literal("TruncWeekMonday"),
  Literal("TruncDay"),
  Literal("TruncHour"),
  Literal("TruncMinute"),
  Literal("TruncSecond"),
  Literal("DiffDays"),
  Literal("DiffHours"),
  Literal("DiffMinutes"),
  Literal("DiffSeconds"),
  Literal("DiffMilliseconds"),
  Literal("Now"),
  Literal("Today"),
  Literal("If"),
  Literal("Switch"),
  Literal("IsOneOf"),
  Literal("DatetimeToEpochMs"),
  Literal("EpochMsToDatetime"),
);
export const HqlScalarFunction = getNormalEnum(HqlScalarFunctionLiteral);
export type HqlScalarFunction = Static<typeof HqlScalarFunctionLiteral>;

export const HqlAggregationFunctionLiteral = Union(
  Literal("Min"),
  Literal("Max"),
  Literal("Avg"),
  Literal("Count"),
  Literal("CountDistinct"),
  Literal("Sum"),
  Literal("StdDev"),
  Literal("StdDevPop"),
  Literal("Variance"),
  Literal("VariancePop"),
  Literal("Median"),
);

export const HqlAggregationFunction = getNormalEnum(
  HqlAggregationFunctionLiteral,
);
export type HqlAggregationFunction = Static<
  typeof HqlAggregationFunctionLiteral
>;

const HqlAggregationFunctionSet = new Set(
  typedObjectValues(HqlAggregationFunction).map((s) => s.toLowerCase()),
);
export const isHqlAggregationFunctionName = (s: string): boolean =>
  HqlAggregationFunctionSet.has(s.toLowerCase());

export const HqlFunction = Union(
  HqlScalarFunctionLiteral,
  HqlAggregationFunctionLiteral,
);
export type HqlFunction = Static<typeof HqlFunction>;

export const HqlBool = RRecord({
  type: Literal("boolean"),
  value: Boolean,
});
export type HqlBool = Static<typeof HqlBool>;

export const HqlFloat = RRecord({
  type: Literal("float"),
  value: Number,
});
export type HqlFloat = Static<typeof HqlFloat>;

export const HqlInteger = RRecord({
  type: Literal("integer"),
  value: Number,
});
export type HqlInteger = Static<typeof HqlInteger>;

export const HqlNull = RRecord({
  type: Literal("null"),
});
export type HqlNull = Static<typeof HqlNull>;

export const HqlStr = RRecord({
  type: Literal("str"),
  value: String,
});
export type HqlStr = Static<typeof HqlStr>;

export const HqlColumn = RRecord({
  type: Literal("column"),
  name: String,
});
export type HqlColumn = Static<typeof HqlColumn>;

export const HqlParameter = RRecord({
  type: Literal("parameterReference"),
  name: String,
});
export type HqlParameter = Static<typeof HqlParameter>;

export const HqlScalar = Union(HqlBool, HqlFloat, HqlInteger, HqlNull, HqlStr);
export type HqlScalar = Static<typeof HqlScalar>;

export type HqlUnaryOp = {
  type: "unaryOp";
  op: HqlUnaryOperator;
  left: HqlNode;
};
export const HqlUnaryOp: Runtype<HqlUnaryOp> = Lazy(() =>
  RRecord({
    type: Literal("unaryOp"),
    op: HqlUnaryOperatorLiteral,
    left: HqlNode,
  }),
);

export type HqlBinaryOp = {
  type: "binaryOp";
  op: HqlBinaryOperator;
  left: HqlNode;
  right: HqlNode;
};
export const HqlBinaryOp: Runtype<HqlBinaryOp> = Lazy(() =>
  RRecord({
    type: Literal("binaryOp"),
    op: HqlBinaryOperatorLiteral,
    left: HqlNode,
    right: HqlNode,
  }),
);

export type HqlFunc = {
  type: "function";
  name: string;
  args: HqlNode[];
};
export const HqlFunc: Runtype<HqlFunc> = Lazy(() =>
  RRecord({
    type: Literal("function"),
    name: String,
    args: Array(HqlNode),
  }),
);

export const HqlNode = Union(
  HqlUnaryOp,
  HqlBinaryOp,
  HqlFunc,
  HqlScalar,
  HqlColumn,
  HqlParameter,
);
export type HqlNode = Static<typeof HqlNode>;

const BaseHqlTransform = RRecord({
  type: String,
});

export const HqlAggregation = RRecord({
  expr: HqlNode,
  as: String,
});
export type HqlAggregation = Static<typeof HqlAggregation>;

export const HqlAggregateTransform = BaseHqlTransform.extend({
  type: Literal("aggregate"),
  groupby: Optional(Array(String).asReadonly()),
  aggregations: Array(HqlAggregation).asReadonly(),
});
export type HqlAggregateTransform = Static<typeof HqlAggregateTransform>;

export const HqlFilterTransform = BaseHqlTransform.extend({
  type: Literal("filter"),
  expr: HqlNode,
});
export type HqlFilterTransform = Static<typeof HqlFilterTransform>;

export const HqlProjection = RRecord({
  column: Optional(String),
  expr: Optional(HqlNode),
  as: Optional(String),
});
export type HqlProjection = Static<typeof HqlProjection>;

export const HqlProjectTransform = BaseHqlTransform.extend({
  type: Literal("project"),
  append: Optional(Boolean),
  projections: Array(HqlProjection),
});
export type HqlProjectTransform = Static<typeof HqlProjectTransform>;

export const HqlSortColumn = RRecord({
  column: String,
  order: Optional(HqlSortOrderLiteral),
});
export type HqlSortColumn = Static<typeof HqlSortColumn>;

export const HqlSortTransform = BaseHqlTransform.extend({
  type: Literal("sort"),
  columns: Array(HqlSortColumn).asReadonly(),
});
export type HqlSortTransform = Static<typeof HqlSortTransform>;

export const HqlLimitTransform = BaseHqlTransform.extend({
  type: Literal("limit"),
  limit: Number,
  offset: Optional(Number),
});
export type HqlLimitTransform = Static<typeof HqlLimitTransform>;

export const HqlBinTransform = BaseHqlTransform.extend({
  type: Literal("bin"),
  column: String,
  maxbins: Optional(Number),
  base: Optional(Number),
  minstep: Optional(Number),
  step: Optional(Number),
  as_prefix: Optional(String),
});
export type HqlBinTransform = Static<typeof HqlBinTransform>;

export const HqlTransform = Union(
  HqlAggregateTransform,
  HqlFilterTransform,
  HqlLimitTransform,
  HqlProjectTransform,
  HqlSortTransform,
  HqlBinTransform,
);
export type HqlTransform = Static<typeof HqlTransform>;

export const HqlQueryParameter = RRecord({
  name: String,
  type: CalciteTypeLiteral,
});
export type HqlQueryParameter = Static<typeof HqlQueryParameter>;

export const HqlQueryColumn = RRecord({
  name: String,
  type: CalciteTypeLiteral,
});
export type HqlQueryColumn = Static<typeof HqlQueryColumn>;

export const HqlQueryTable = RRecord({
  columns: Array(HqlQueryColumn),
  /** row-based data corresponding to `columns` */
  values: Optional(Array(Array(Unknown)).asReadonly()),
});
export type HqlQueryTable = Static<typeof HqlQueryTable>;

const HqlTableName = String;

export const HqlQuery = RRecord({
  tables: Dictionary(HqlQueryTable, HqlTableName),
  from: String,
  transforms: Optional(Array(HqlTransform).asReadonly()),
});
export type HqlQuery = Static<typeof HqlQuery>;
